export const generalError = `## Oops!!!

Something went wrong while processing your application. 
Please try submitting your application again. If the issue persists, please contact our Customer Service  at 

[(866) 393-8636](tel:(866)393-8636)

for immediate assistance.
Thank You.
`;

export const ssnValidationFail = `## Oops!!!

Unfortunately, we are not able to match the social security number you provided to the email address in our Data Base. Please click OK to start over or check your email for your unique application link previously sent to you.

In case you keep seeing this page, please contact our support team for additional assistance at

[(866) 393-8636](tel:(866)393-8636)

M-F from 9am to 5pm EST.`;

export const verifyingInfo = `## Verifying Information

Thank you. We’re processing your request and may need more information from you. We appreciate your patience. Please don’t click “Refresh” or “Back” arrow in your browser window or your request will be lost.`;

export const incompleteApplication = `## Incomplete Application

We found an incomplete application which you recently began. You will be redirected to this application to complete it.`;

export const completeApplication = `## Application successfully submitted!

You will receive an email confirming when your funds have been processed. 
Should we need additional information, an agent will contact you.`;

export const supportingDocuments = `## WHAT DOCUMENTS TO PROVIDE

If you are unsure what documents to provide, please refer to the guide below:

**NOTE**

If your business is registered with the secretary of state and you have been issued an EIN number by the IRS, please also upload a copy of your EIN assignment letter in addition to one of the documents below.

- **1099 independent contractor** and you are paid by a company, you will need to provide one of the following:

    - your previous year's 1099 Form. This is the form used for filing your taxes. (PREFERRED)
    - your most recent paystub,
    - Your most recent settlement statement, or
    - an invoice that corresponds to a deposit that was received within the last 90 days,

- **Self-Employed or a Sole Proprietor** and you are paid by various individual people (e.g. Freelance, Hairstylist, IT, Handyman, etc):

    - If you are paid through 3rd party apps - a Screenshot of your profile
    - If you are paid directly by your customers (cash or checks) - Provide a business card, a photo of your branded truck, or any other documentation that will assist us in verifying your business operations.

- **Platform Worker** (e.g. Lyft, Uber, Doordash, Instacart):

    - a screenshot of your driver profile showing your name and rating

- **Ecommerce Operator** (e.g. Etsy, eBay, Shopify, Poshmark, etc):

    - An account statement from the last 90 days showing your name and the last 90 days of sales activity
`;

export const pingyoSuccess = `## Congratulations!

We've found a perfect match for your funding needs. You are about to be redirected to our partner's website to complete your application. Your financial journey is in good hands!
`;

export const pingyoDenied = `## Unfortunately, No Match Found

This time, we were unable to find a suitable match for your funding needs.

If you have any questions or need assistance, please contact us at [support@fundo.com](mailto:support@fundo.com) or call [1-866-393-8636](tel:(866)393-8636) between 9 am to 5 pm EST, Monday to Friday.
`;